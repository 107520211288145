'use strict';

angular.module('enervexSalesappApp').factory('Util', function Util(_, Auth, Product) {
	return {
		validateInclude: function(design, application) {
			switch (application.computeAs) {
				case "BWH":
				case "DRY":
					var include = design.include;
					if (!include || (!include.exhaust && !include.stack)) {
						throw new Error("You must include exhaust or stack")
					}
					break;
				default:
					// code block
			}
		},
		frameworkVersion: 2,
		validateInclude: function(design, application) {
			switch (application.computeAs) {
				case "BWH":
				case "DRY":
					var include = design.include;
					if (!include || (!include.exhaust && !include.stack)) {
						throw new Error("You must include exhaust or stack")
					}
					break;
				default:
					// code block
			}
		},
		checkSystems:function(design, lineitemSources) {
			if (!design.fc5layout) {
				design.fc5layout = {}
			}
			if (!design.fc5layout.systems) {
				design.fc5layout.systems = [{
					id: 1,
					label: 'SYS'+1
				}]
			}
			_.each(design.items, function(item){
				if (item.origin == "manual") {
					if (!item.source) {
						item.source = {}
					}
					_.each(lineitemSources, function(o){
						if (!item.source[o.code]) {
							item.source[o.code] = 0
						}
					})
					var found = _.find(lineitemSources, function(o){
						if (item.source[o.code] == item.quantity) {
							return true
						}
					})
					if (found) {
						var systemSource = _.first(item.systemSources)
						if (!(systemSource && systemSource[found.code] == item.quantity)) {
							var systemSource = {
								systemId: 1
							}
							_.each(lineitemSources, function(o){
								systemSource[o.code] = 0
							})
							systemSource[found.code] = item.quantity
							item.systemSources = [systemSource]
						}
					} else {
						item.source.other = item.quantity
						var systemSource = {
							systemId: 1,
						}
						_.each(lineitemSources, function(o){
							systemSource[o.code] = 0
						})
						systemSource.other = item.quantity
						item.systemSources = [systemSource]
					}
				}
			})
		},
		toJsonObject:function(obj) {
			var json = angular.toJson(obj)
			var parsed = JSON.parse(json)
			return parsed
		},
		isDry:function(appliance, design) {
			return design.application.computeAs == 'DRY' || (appliance && appliance.computeAs == "DRY")
		},
		isBwh:function(appliance, design) {
			return design.application.computeAs == 'BWH' && !(appliance && appliance.computeAs == "DRY")
		},
		totalLineitem: function(item) {
			var result = {
				price: 0,
				totalPrice: 0,
				totalPriceExhaust: 0,
				totalPriceSupply: 0,
				totalPriceOther: 0,
				totalPriceStack: 0,
				totalCount: 0,
				totalCountStack:0,
				totalCountExhaust: 0,
				totalCountOther: 0,
				totalCountSupply: 0
			}
			if (!item.removed){
				if (item.itemType == 'custom'){
					result.price = this.get(item,'customProduct.price')
				} else {
					if (item.product) {
						result.price =  this.get(item, 'product.price')
					}
				}
				result.totalCount = item.quantity;
				result.totalCountStack = this.get(item ,'source.stack')
				result.totalCountExhaust = this.get(item, 'source.exhaust')
				result.totalCountOther = this.get(item, 'source.other')
				result.totalCountSupply = this.get(item, 'source.supply')
				result.totalPriceExhaust = result.totalCountExhaust * result.price
				result.totalPriceSupply = result.totalCountSupply * result.price
				result.totalPriceOther = result.totalCountOther * result.price
				result.totalPriceStack = result.totalCountStack * result.price
				result.totalPrice = result.totalCount * result.price

				item.list = result.price
				item.totalList = result.totalPrice
			}
			return result
		},
		get:function(object, path) {
			var props = path.split(".")
			var value = object
			for (var i=0; i< props.length; i++) {
				var propName = props[i]
				if (value) {
					value = value[propName]
				}
			}
			return value
		},
		// productTypes: ['Accessory', 'StackSection', 'FittingProduct', 'BaffleProduct'],
		setCodeWarning: function() {
			return 'Code is referenced data, please edit with caution.'
		},
		getProductType:function(_productType, productTypes) {
			return _.find(productTypes, function(o){
				return o.designator == _productType
			})
		},
		getSubtypes: function(_productType, productSubtypes, design) {
			return _.filter(productSubtypes, function(subtype){
				var exists = _.find(subtype.productTypes, function(productType){
					return productType.designator == _productType
				})
				if (!exists) {
					return false
				}
				if (design) {
					var supportsApplication = _.find(subtype.applications, function(app){
						return app._id == design.application._id
					})
					return (supportsApplication) ? true : false
				} else {
					return true
				}
			})
		},
		sortUncased: function(collection, property) {
			return _.sortBy(collection, function(o){
				return o[property].toLowerCase()
			})
		},
		faultItemNameGUIident: function(GUIident, fault, design){
			if (fault.type == "appliances"){
				return "A" + GUIident;
			} else if (fault.type == "vents") {
				return "S" + GUIident;
			} else {
				console.log("unknown type ", fault.type)
			}
		},
		hasMultipleSystems: function(design) {
			try {
				return design.fc5layout.systems.length > 1
			}catch(e) {
				return false
			}
		},
		faultItemName: function(faultItem, fault, design){
			if (this.hasMultipleSystems(design)) {
				if (fault.type == "appliances" || fault.faulttype == "appliances"){
					return "A" + (faultItem.Appl && faultItem.Appl.GUIidentPath);
				} else if (fault.type == "vents" || fault.faulttype == "vents") {
					return "S" + faultItem.GUIidentPath;
				} else {
					console.log("unknown type ", fault.type)
				}
			} else {
				if (fault.type == "appliances" || fault.faulttype == "appliances"){
					return "A" + (faultItem.Appl && faultItem.Appl.GUIident);
				} else if (fault.type == "vents" || fault.faulttype == "vents") {
					return "S" + faultItem.GUIident;
				} else {
					console.log("unknown type ", fault.type)
				}
			}
		},
		hasCat: function(design, categories) {
			var found = _.find(design.fc5layout.Layout, function(v){
				if (v.Fit1 == "STP" && v.Appl) {
					var existing = _.find(categories, function(category){
						if (category == v.Appl.Cat) {
							// console.log("has cat "+category, v.Appl.Cat)
							return true
						}
					})
					return existing ? true : false
				}
			})
			return found ? true : false
		},
		getAppliances: function(design) {
			var appliances = _.map(design.fc5layout.Layout, function(section) {
				if (section.Appl && (section.Fit1 == "STP" || section.Fit2 == "STP")) {
					return section.Appl
				}
			});

			if (design.application.computeAs == "COM") {
				_.each(design.fc5layout.Lines, function(section) {
					for (var q = 1; q <= section.Qty; q++) {
						section.Qty = section.Qty ? section.Qty : 1
						appliances.push(section)
					}
				});
			}

			return _.compact(appliances)
		},
		getApplianceSections: function(design) {
			return _.filter(design.fc5layout.Layout, function(section) {
				return (section.Appl && (section.Fit1 == "STP" || section.Fit2 == "STP"))
			});
		},
		reduceId: function(obj) {
			if (obj && obj._id) {
				return obj._id
			} else {
				return obj
			}
		},
		getSelectedEconmizer: function(economizerId, economizers) {
			return _.find(economizers, function(economizer){
				return economizer._id == economizerId
			})
		},
		economizerSectionFit1: function(design) {
			if (!design){
				return null
			}
			return _.find(design.fc5layout.Layout, function(v) {
				return (v.Fit1 == "ECO" || v.Fit1 == "ECM")
			})
		},
		economizerSectionFit2: function(design) {
			if (!design){
				return null
			}
			return _.find(design.fc5layout.Layout, function(v) {
				return (v.Fit2 == "ECO" || v.Fit2 == "ECM")
			})
		},
		isTermination:function(vent){
			return vent.Fit1 == 'TER' || vent.Fit1 == 'TER' || vent.Fit2 == 'TER' || vent.Fit2 == 'TER'
		},
		updateEconomizer: function(design, economizers) {
			var economizer = this.getSelectedEconmizer(design.economizer, economizers)
			var section1 = this.economizerSectionFit1(design)
			if (section1) {
				if (!section1.ECM) {
					section1.ECM = {}
				}
				section1.ECM.KValue = economizer.kValue;
				section1.ECM.MaxT = economizer.maxTempOut;
				section1.ECM.Dia = economizer.centerPointA.dim1;
			}
			var section2 = this.economizerSectionFit2(design)
			if (section2) {
				if (!section2.ECM) {
					section2.ECM = {}
				}
				section2.ECM.KValue = economizer.kValue;
				section2.ECM.MaxT = economizer.maxTempOut;
				section2.ECM.Dia = economizer.centerPointA.dim1;
			}
		},
		economizerProperties: ["KValue","MaxT","Dia"],
		economizerExtraProperties: ["energySavingsMax","annualOperatingHours","annualSavings","savingsGoal","efficiencyFromVhx","newBoilerEfficiency","combustionEfficiency","firingRate","fuelCostPerTherm"],
		updateEconomizerValue: function(design, ecm) {
			console.log("setting ECM to ", ecm)
			if (ecm.economizer) {
				design.economizer = ecm.economizer
			}
			var section1 = this.economizerSectionFit1(design)
			if (section1) {
				if (!section1.ECM) {
					section1.ECM = {}
				}
				_.each(this.economizerProperties, function(name){
					section1.ECM[name] = ecm[name];
				})
				_.each(this.economizerExtraProperties, function(name){
					section1.ECM[name] = ecm[name];
				})
			}
			var section2 = this.economizerSectionFit2(design)
			if (section2) {
				if (!section2.ECM) {
					section2.ECM = {}
				}
				_.each(this.economizerProperties, function(name){
					section2.ECM[name] = ecm[name];
				})
				_.each(this.economizerExtraProperties, function(name){
					section2.ECM[name] = ecm[name];
				})
			}
		},
		getInlets: function(v, design) {
			return _.filter(design.fc5layout.Layout, function(vv) {
				return (vv.X2 == v.X1 && vv.Y2 == v.Y1 && vv.Z2 == v.Z1)
			})
		},
		getEndsIn: function(v, design) {
			return _.filter(design.fc5layout.Layout, function(vv) {
				return (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1)
			})
		},
		getOutlet: function(v, design) {
			var result =  _.filter(design.fc5layout.Layout, function(vv) {
				return (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1)
			})
			if (result && result.length > 0){
				return result[0]
			}
		},
		ComingTo: function(x, y, z, Layout) {
			return _.filter(Layout, function(v) {
				return v.X2 == x && v.Y2 == y && v.Z2 == z;
			});
		},
		setAutoFitting: function(v, inlets, design, fittings, target) {
			if (v.Fit1 == "") {
				v.Fit1 = this._setAutoFitting(v, inlets, design, fittings, target)
				console.log("set Fit1 to", v.Fit1, v.GUIidentPath, target)

				_.each(inlets, function(vv) {
					console.log("set Fit2 to", v.Fit1, v.GUIidentPath, target)
					vv.Fit2 = v.Fit1;
				})
			}
		},
		setFit1: function(v, code, design) {
			var inlets = this.getInlets(v, design)
			v.Fit1 = code
			// console.log("set vent.Fit1 to "+v.Fit1)
			_.each(inlets, function(vv) {
				vv.Fit2 = code;
			})
		},
		_setAutoFitting: function(v, inlets, design, fittings, target) {
			if (inlets.length == 3) {
				if (design.application.computeAs == "DRY") {
					return "SUB"
				} else if (v.DimY > 0 && v.DimX == 0 && v.DimZ == 0 && v.Shape !== "ROU") {
					return "SUB";
				} else {
					return "DLT";
				}
			} else if (inlets.length == 2) {
				if (design.application.computeAs == "DRY") {
					return "SUB"
				} else if (v.DimY > 0 && v.DimX == 0 && v.DimZ == 0 && v.Shape !== "ROU") {
					return "LT4";
				} else {
					// return "DLT";
					return "LT4";
				}
			} else if (inlets.length == 1) {
				var Fit = "90L"
				var DirCode = "";
				var InSec = inlets[0];
				DirCode = this.getOrientation(InSec) + "2" + this.getOrientation(v);

				switch (design.application.computeAs) {
					case "BWH":
						if (DirCode == "H2V") {
							Fit = "SQT"
						}
						break;
						// case "GRE":
						// 	if (DirCode == "H2V") {
						// 		Fit = "GRT"
						// 	} else if (DirCode == "V2H") {
						// 		Fit = "SQT"
						// 	} else {
						// 		Fit = "90L"
						// 	}

						// 	break;
						// case "ENG":
						// 	if (DirCode == "H2V") {
						// 		Fit = "LT4"
						// 	} else {
						// 		Fit = "90L"
						// 	}
						// 	break;
					default:
						Fit = "90L";
				}

				if (Fit == "90L") {
					switch (Math.round(this.GetTrueAngle(inlets[0], v))) {
						case 0:
							Fit = "NON";
							break;
						case 150:
							Fit = "60L";
							break;
						case 135:
							Fit = "45L";
							break;
						case 120:
							Fit = "30L";
							break;
						case 105:
							Fit = "15L"
							break;
						default:
							Fit = "90L"
					}
				}

				if (!this.fitContains(Fit, v.VentType, fittings, design)) {
					return "90L"
				} else {
					return Fit;
				}
			} else if (inlets.length == 0) {
				return "STP";
			} else {
				//shouldn't happen
				throw "Unusual set of inlets"
			}
		},
		getOrientation: function(v) {
			var Vert = v.DimY;
			var Hori = Math.sqrt(v.DimX * v.DimX + v.DimZ * v.DimZ);
			if (Vert >= Hori) {
				return "V";
			} else {
				return "H";
			}
		},
		LeavingFrom: function(x, y, z, Layout) {
			return _.find(Layout, function(v) {
				return v.X1 == x && v.Y1 == y && v.Z1 == z;
			});
		},
		_getFittings: function(secno, mat, fittings, design) {
			var self = this;
			return _.filter(fittings, function(fit) {
				if (fit.code == "") {
					return true
				}
				return ((secno != 1 && fit.secno >= secno && self.fitContains(fit.code, mat, fittings, design)) || (fit.secno == 1 && secno == 1))
			})
		},
		fitContains: function(fitment, VentType, fittings, design) {
			if (design.application.computeAs == 'DRY') {
				return true;
			}
			var fitting = _.find(fittings, function(f) {
				return f.code == fitment
			})
			var existing = _.find(fitting.ventMaterials, function(v) {
				return v.code == VentType
			})
			return (existing) ? true : false
		},
		direction: function(v, design) {
			if (design.viewMode == '3D'){
				var GreenX = (v.X1 != v.X2);
				var GreenY = (v.Y1 != v.Y2);
				var GreenZ = (v.Z1 != v.Z2);

				return {
					x: GreenX,
					y: GreenY,
					z: GreenZ
				}
			} else {
				var GreenX = (v.X1 != v.X2);
				var GreenY = (v.Y1 != v.Y2);

				return {
					x: GreenX,
					y: GreenY,
				}
			}
		},
		getCustomFittings: function(design) {
			var customFittings = design.customComponents.map(function(fitting) {
				return fitting
			})
			return customFittings
		},
		getFittings: function(Layout, v, design, fittings, for1) {
			var Fit1No = 0;
			var Fit2No = 0;
			var col1 = this.ComingTo(v.X1, v.Y1, v.Z1, Layout);
			var col2 = this.ComingTo(v.X2, v.Y2, v.Z2, Layout);
			var Lea = this.LeavingFrom(v.X2, v.Y2, v.Z2, Layout);

			var Mat1 = v.VentType;
			var Mat2 = v.VentType;
			// var Mat2 = "";
			// if (Lea != null) {
			// 	Mat2 = Lea.VentType;
			// } else {
			// 	Mat2 = v.VentType;
			// }

			Fit1No = col1.length + 1;
			Fit2No = col2.length;

			if (Lea != null) {
				Fit2No++;
			};

			// if (this.Ais1(v, design) == true) {
			if (for1) {
				return this._getFittings(Fit1No, Mat1, fittings, design);
			} else {
				return this._getFittings(Fit2No, Mat2, fittings, design);
			}
			// } else {
			// 	if (for1){
			// 		return this._getFittings(Fit2No, Mat1, fittings, design);
			// 	} else {
			// 		return this._getFittings(Fit1No, Mat2, fittings, design);
			// 	}
			// }
		},
		availableVentMaterials: function(applianceCategories, design, allVentMaterials) {
			if (design.include.exhaust == false) {
				return allVentMaterials
			}
			if (design.application.computeAs == "DRY" && design.application.stackAs == "BWH") {
				return _.filter(allVentMaterials, function(ventMaterial){
					var found = _.find(design.application.ventMaterials, function(id){
						return id == ventMaterial._id
					})
					return (found) ? true : false
				})
			}
			var appliances = this.getAppliances(design);
			var filtered = _.filter(applianceCategories, function(category) {
				var existing = _.find(category.buildTypes, function(bt) {
					return bt._id == design.buildType
				})
				return (existing) ? true : false;
			});
			var category = _.find(filtered, function(category) {
				if (category.ruleType == 'single') {
					var appliance = _.find(appliances, function(f) {
						return f.Cat == category.name;
					})
					if (appliance) {
						return true;
					}
				} else {
					var missing = false;
					_.each(category.names, function(name) {
						var appliance = _.find(appliances, function(a) {
							return a.Cat == name
						})
						if (!appliance) {
							missing = true
						}
					})
					return !missing
				}
			})
			if (category) {
				// console.log("matched on category", category)
				return _.filter(category.ventMaterials, function(vm){
					var existing = _.find(vm.buildTypes, function(bt){
						return bt._id == design.buildType
					})
					return (existing) ? true : false
				})
			} else {
				return []
			}
		},
		enforcePrecision: function(val, precision) {
			var factor = Math.pow(10, precision);
			var result = Math.round(val * factor) / factor;
			return result;
		},
		isMultiStory: function(design) {
			var existing = _.find(this.getAppliances(design), function(appliance) {
				return appliance.Multilevel == true
			})
			return (existing) ? true : false;
		},
		availableControls: function(controls, design, isConstantVol, SupFan, ExhFan) {
			var self = this;
			return _.filter(controls, function(control) {
				var setting = _.find(control.applicationSupport, function(appSupport) {
					return appSupport.computeAs == design.application.computeAs
				});

				if (!setting) {
					return false;
				} else {
					var excludeSup = _.find(setting.excludeIfFan, function(fan) {
						return fan == SupFan;
					});
					var excludeExh = _.find(setting.excludeIfFan, function(fan) {
						return fan == ExhFan;
					});
					if (SupFan != "None" && !setting.includeSupplyNotNone) {
						return false
					} else if (SupFan != "None" && ExhFan != "None" && !setting.bothSupplyAndExhaust) {
						return false
					} else if (!isConstantVol && setting.includeNonConstantVol == false) {
						return false
					} else if (self.getAppliances(design).length > setting.maxNumberAppliances) {
						return false
					} else if (self.isMultiStory(design) && setting.supportsMultiStory == false) {
						return false
					} else if (excludeExh) {
						return false;
					} else if (excludeSup) {
						return false;
					} else if (setting.supportDamper == false && design.damper == true) {
						return false
					} else {
						return true
					}
				}
			});
		},
		setNotifyMembersType: function(obj, job) {
			if (!obj.notifyMembers || obj.notifyMembers.length == 0) {
				obj.notifyMembersType = 'none'
			} else if (obj.notifyMembers.length == (job && job.members.length)) {
				obj.notifyMembersType = 'all'
			} else {
				obj.notifyMembersType = 'some'
			}
		},
		defaultVisibility: function(job) {
			return (Auth.isInternalMember(job)) ? 'internal' : 'external'
		},
		_fanSettingStore: function(type, design) {
			var settingStore = null
			if (type == "exhaust") {
				if (!(design.exhFanOptions && design.exhFanOptions.fans)) {
					settingStore = design.exhFanOptions = {
						fans: []
					}
				} else {
					return design.exhFanOptions
				}
			} else {
				if (!(design.supFanOptions && design.supFanOptions.fans)) {
					settingStore = design.supFanOptions = {
						fans: []
					}
				} else {
					return design.supFanOptions
				}
			}
			return settingStore
		},
		toYesNo: function(val) {
			if (val == true) {
				return "Yes"
			} else if (val == false) {
				return "No"
			}
		},
		getFanOption: function(type, fan, design) {
			var settingStore = this._fanSettingStore(type, design)
			return _.find(settingStore.fans, function(f) {
				return (f.Name == fan.Name)
			})
		},
		setFanOption: function(type, option, design) {
			var settingStore = this._fanSettingStore(type, design)
			settingStore.fans = _.filter(settingStore.fans, function(f) {
				return f.Name != option.Name
			})
			settingStore.fans.push(option)
		},
		createAppliance: function(design) {
			// console.log("createAppliance()", design.application)
			if (design.application.computeAs == "DRY") {
				return {
					Defined: false,
					GUIident: "",
					InputType: "CFM",
					PresT: 0,
					PresHType: "in.W.C.",
					PresLType: "in.W.C.",
					PresType: "in.W.C.",
					Icon: "DRY",
					Volume: 0,
					OutDia: 4,
					PresL: 0,
					PresH: 0.3,
					Multilevel: false
				}
			} else {
				return {
					Defined: false,
					GUIident: "",
					Label: "",
					Manufacturer: "",
					ManuID: "",
					Model: "",
					ModelID: "",
					InputH: 0,
					InputL: 0,
					InputType: "MBTU",
					CO2H: 0,
					CO2L: 0,
					TempH: 0,
					TempL: 0,
					TempType: "°F",
					FuelCode: "",
					// FuelOpt:"",
					PresH: 0,
					PresL: 0,
					PresT: 0,
					PresHType: "in.W.C.",
					PresLType: "in.W.C.",
					PresType: "in.W.C.",
					OpsModeCode: "Normal",
					OutShape: "ROU",
					Diameter: 0,
					OutDim1: 0,
					OutDim2: 0,
					//see lstIconOptions for options
					Icon: "cat1dh",
					//TODO: conditionally set to mm/in if design.units == SI/IP
					OutDimType: "in",
					Cat: "",
					Baro: "No",
					OutletDamp: "No",
					OutletCode: ""
				}
			}
		},
		createEcm: function() {
			return {
				ID: newID(),
				KValue: 0,
				MaxT: 0,
				Dia: 0
			}
		},
		LoadFunctions: function(a) {
			a.X = function() {
				return this.X1 + (this.X2 - this.X1) / 2;
			}
			a.Y = function() {
				return this.Y1 + (this.Y2 - this.Y1) / 2;
			}
			a.Z = function() {
				return this.Z1 + (this.Z2 - this.Z1) / 2;
			}
		},
		createSection: function(X1, Y1, X2, Y2, Z1, Z2, design) {
			var section = {
				ID: newID(),
				X1: X1,
				X2: X2,
				Y1: Y1,
				Y2: Y2,
				Z1: Z1,
				Z2: Z2,
				Selected: false,
				//TODO: remember last Dim1 and use it
				Dim1: 0,
				Dim2: 0,
				//TODO: remember last shape
				Shape: "ROU",
				DimX: 0,
				DimY: 0,
				DimZ: 0,
				Fit1: "",
				Fit2: "",
				AddPres: 0,
				Emb90: 0,
				Emb45: 0,
				Appl: undefined,
				TermFan: undefined,
				GUIident: "",
				Icon: "",
				ProcessCheck: false,
				Optimize: "Yes",
				originalViewMode: design.viewMode,
				originalViewAngle: design.fc5layout.ViewAngle
			}
			if (design.include.exhaust == false) {
				section.Optimize = "No"
			}
			if (design.application.computeAs == "BWH") {
				section.AddK = 0;
				section.ECM = undefined;
				section.MODS = false;
				section.DamperSection = true;
			}
			try {
				section.VentType = design.application.defaultVentMaterial.code;
			} catch(e) {
				section.VentType = "EPS1"
			}
			

			this.LoadFunctions(section);
			return section
		},
		canInsertLine: function(v) {
			//need to see that difference between sections is at least 50
			if (v.X1 != v.X2 && Math.abs(v.X1-v.X2) < 50) {
				return false
			}
			if (v.Y1 != v.Y2 && Math.abs(v.Y1-v.Y2) < 50) {
				return false
			}
			if (v.Z1 != v.Z2 && Math.abs(v.Z1-v.Z2) < 50) {
				return false
			}
			return true
		},
		createSectionFromBreak: function(X1, Y1, X2, Y2, Z1, Z2, design, originalSection, firstBreak) {
			var section = {
				ID: newID(),
				X1: X1,
				X2: X2,
				Y1: Y1,
				Y2: Y2,
				Z1: Z1,
				Z2: Z2,
				Selected: false,
				//TODO: remember last Dim1 and use it
				Dim1: originalSection.Dim1,
				Dim2: originalSection.Dim2,
				//TODO: remember last shape
				Shape: originalSection.Shape,
				DimX: originalSection.DimX,
				DimY: originalSection.DimY,
				DimZ: originalSection.DimZ,
				Fit1: firstBreak ? originalSection.Fit1 : "",
				Fit2: !firstBreak ? originalSection.Fit2: "",
				AddPres: originalSection.AddPres,
				Emb90: originalSection.Emb90,
				Emb45: originalSection.Emb45,
				Appl: firstBreak ? originalSection.Appl : undefined,
				TermFan: originalSection.TermFan,
				GUIident: "",
				Icon: originalSection.Icon,
				ProcessCheck: originalSection.ProcessCheck,
				Optimize: originalSection.Optimize
			}
			// for boilers
			if (design.application.computeAs == "BWH") {
				section.AddK = originalSection.AddK;
				section.ECM = originalSection.ECM;
				section.MODS = originalSection.MODS;
				section.DamperSection = originalSection.DamperSection;
				//TODO: setup defaults on VentType
				section.VentType = originalSection.VentType;
			}

			this.LoadFunctions(section);
			return section
		},
		formatLength: function(num, ipAsInches, design, removeSpace) {
			if (design.units == "IP") {
				if (ipAsInches) {
					if (!num) {
						return "0"
					}
					return "" + num
				} else {
					var ft = parseInt(num / 12);
					var inc = Math.round(parseFloat(num - (ft * 12)) * 1000) / 1000;
					if (ft > 0) {
						if (removeSpace) {
							return ft + "'" + inc;
						} else {
							return ft + "' " + inc;
						}

					} else {
						return inc;
					}
				}
			} else {
				return Math.round(parseFloat(num) * 1000) / 1000;
			}
		},
		Ais1: function(v, design) {
			switch (design.fc5layout.ViewAngle) {
				case "Plan":
					if (v.Z1 != v.Z2) {
						if (v.Z2 > v.Z1) {
							return false;
						} else {
							return true;
						}
					} else if (v.X1 != v.X2) {
						if (v.X2 > v.X1) {
							return true;
						} else {
							return false;
						}
					}
					break;

				case "Left":
					if (v.Y1 != v.Y2) {
						if (v.Y2 > v.Y1) {
							return true;
						} else {
							return false;
						}
					} else if (v.Z1 != v.Z2) {
						if (v.Z2 > v.Z1) {
							return true;
						} else {
							return false;
						}
					}
					break;

				case "Front":
					if (v.Y1 != v.Y2) {
						if (v.Y2 > v.Y1) {
							return true;
						} else {
							return false;
						}
					} else if (v.X1 != v.X2) {
						if (v.X2 > v.X1) {
							return true;
						} else {
							return false;
						}
					}
					break;

				case "Right":
					if (v.Y1 != v.Y2) {
						if (v.Y2 > v.Y1) {
							return true;
						} else {
							return false;
						}
					} else if (v.Z1 != v.Z2) {
						if (v.Z2 > v.Z1) {
							return false;
						} else {
							return true;
						}
					}
					break;

				case "TopRight":
				case "TopLeft":
					if (v.Y1 != v.Y2) {
						if (v.Y2 > v.Y1) {
							return true;
						} else {
							return false;
						}
					} else if (v.X1 != v.X2) {
						if (v.X2 > v.X1) {
							return true;
						} else {
							return false;
						}
					} else if (v.Z1 != v.Z2) {
						if (v.Z2 > v.Z1) {
							return true;
						} else {
							return false;
						}
					}
					break;
			}
		},
		_float: function(value) {
			value = parseFloat(value);
			if (isNaN(value)) {
				value = 0;
			}
			return value;
		},
		_int: function(value) {
			value = parseInt(value);
			if (isNaN(value)) {
				value = 0;
			}
			return value;
		},
		_round: function(num) {
			return Math.ceil(num * 100) / 100;
		},
		versionInfo: function() {
			return {
				version: "1.0.0"
			}
		},
		parseBomFilter: function(filter, condition) {
			var regex = /this.ExhFan *([^ ]*) *'([^']*)'/;
			var found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "!=" && value == "None") {
					condition.exhFansLogic = 'not none'
				} else if (cond == "==" && value == "None") {
					condition.exhFansLogic = 'none'
				} else if (cond == "==") {
					condition.exhFansLogic = 'selected';
					condition.exhFans = [value]
				} else if (cond == "!=") {
					condition.exhFansLogic = 'not selected';
					condition.exhFans = [value]
				}
			}
			var regex = /this.SupFan *([^ ]*) *'([^']*)'/;
			var found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "!=" && value == "None") {
					condition.supFansLogic = 'not none'
				} else if (cond == "==" && value == "None") {
					condition.supFansLogic = 'none'
				} else if (cond == "==") {
					condition.supFansLogic = 'selected';
					condition.supFans = [value]
				} else if (cond == "!=") {
					condition.supFansLogic = 'not selected';
					condition.supFans = [value]
				}
			}
			regex = /this.Voltage *([^ ]*) *'([^']*)'/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "==") {
					condition.voltagesLogic = 'selected';
					condition.voltages = [value]
				} else if (cond == "!=") {
					condition.voltagesLogic = 'not selected';
					condition.voltages = [value]
				}
			}
			regex = /this.Application *([^ ]*) *'([^']*)'/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "==") {
					condition.computeAsesLogic = 'selected';
					condition.computeAses = [value]
				} else if (cond == "!=") {
					condition.computeAsesLogic = 'not selected';
					condition.computeAses = [value]
				}
			}
			regex = /this.EndPoint.Shape *([^ ]*) *'([^']*)'/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				condition.endpointShapeLogic = cond;
				condition.endpointShape = value
			}
			regex = /this.FlueSystem.DamperSection.Shape *([^ ]*) *'([^']*)'/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				condition.damperShape = value;
			}
			regex = /this.Control *([^ ]*) *'([^']*)'/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				condition.controlsLogic = (cond == "==") ? 'selected' : 'no selected';
				condition.controls = [value]
			}
			regex = /this.FlueSystem.EndPoint.Diameter *([^ ]*) *([0-9]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				condition.endpointDiameterLogic = cond;
				condition.endpointDiameter = parseInt(value)
			}
			regex = /parseInt\(this.FlueSystem.DamperSection.Diameter\) *([^ ]*) *([0-9]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				if (cond == "==") {
					var value = found[2].trim()
					condition.damperDiametersLogic = (cond == "==") ? "selected" : "not selected";
					condition.damperDiameters = [parseInt(value)]
				}
			}
			regex = /this.ExhSinglePhase *([^ ]*) *([^ ]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "&&") {
					condition.exhSinglePhase = true
				} else if (cond == "==") {
					if (value == "true") {
						condition.exhSinglePhase = true
					} else if (value == "false") {
						condition.exhSinglePhase = false
					}
				}
			}
			regex = /this.SupSinglePhase *([^ ]*) *([^ ]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "&&") {
					condition.supSinglePhase = true
				} else if (cond == "==") {
					if (value == "true") {
						condition.supSinglePhase = true
					} else if (value == "false") {
						condition.supSinglePhase = false
					}
				}
			}
			regex = /this.Damper *([^ ]*) *([^ ]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "&&") {
					condition.damper = true
				} else if (cond == "==") {
					if (value == "true") {
						condition.damper = true
					} else if (value == "false") {
						condition.damper = false
					}
				}
			}
			regex = /this.FlueSystem.IsCondensing *([^ ]*) *([^ ]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "&&") {
					condition.condensing = true
				} else if (cond == "==") {
					if (value == "true") {
						condition.condensing = true
					} else if (value == "false") {
						condition.condensing = false
					}
				}
			}
			regex = /this.FlueSystem.ConstantVol *([^ ]*) *([^ ]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				if (cond == "&&") {
					condition.constantVol = true
				} else if (cond == "==") {
					if (value == "true") {
						condition.constantVol = true
					} else if (value == "false") {
						condition.constantVol = false
					}
				}
			}
			regex = /this.EndPoint.Diameter *([^ ]*) *([0-9]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				condition.endpointDiameterLogic = cond;
				condition.endpointDiameter = parseInt(value)
			}
			regex = /this.ApplCount *([^ ]*) *([0-9]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				condition.combustionAppliancCountLogic = cond;
				condition.combustionAppliancCount = parseInt(value)
			}
			regex = /this.FlueSystem.Appliances.length *(>=) *([0-9]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim();
				condition.minAppliances = parseInt(value)
			}
			regex = /this.FlueSystem.Appliances.length *(<=) *([0-9]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim();
				condition.maxAppliances = parseInt(value)
			}
			regex = /!this.FlueSystem.DamperSection/;
			found = filter.match(regex);
			if (found && found.length > 0) {
				condition.hasDamperSection = false;
			}
			regex = /[!]?this.isIn\(\[([^\]]*)] *, *([^)]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var listValues = found[1].split(',')
				listValues = _.map(listValues, function(value) {
					return value.replace(/'/g, "")
				})
				var property = found[2].trim();
				var not = (found[0].substr(0, 1) == "!");
				if (property == "this.ExhFan") {
					if (not) {
						condition.exhFansLogic = 'not selected';
						condition.exhFans = listValues
					} else {
						condition.exhFansLogic = 'selected';
						condition.exhFans = listValues
					}
				} else if (property == 'parseInt(this.v.Diameter') {
					if (not) {
						condition.ventDiametersLogic = 'not selected';
						condition.ventDiameters = listValues
					} else {
						condition.ventDiametersLogic = 'selected';
						condition.ventDiameters = listValues
					}
				} else if (property == 'parseInt(this.FlueSystem.DamperSection.Diameter') {
					if (not) {
						condition.damperDiametersLogic = 'not selected';
						condition.damperDiameters = listValues
					} else {
						condition.damperDiametersLogic = 'selected';
						condition.damperDiameters = listValues
					}
				} else if (property == "this.SupFan") {
					if (not) {
						condition.supFansLogic = 'not selected';
						condition.supFans = listValues
					} else {
						condition.supFansLogic = 'selected';
						condition.supFans = listValues
					}
				}
			}
			regex = /this.ExhFanQuan *([^ ]*) *([^ ]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				condition.exhFanQuanLogic = cond;
				condition.exhFanQuan = parseInt(value)
			}
			regex = /this.v.Baffle *([^ ]*) *'([^']*)'/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				var value = found[2].trim()
				condition.baffle = value;
			}

			regex = /parseInt\(this.v.Diameter\) *([^ ]*) *([^ ]*)/;
			found = filter.match(regex);
			if (found && found.length > 1) {
				var cond = found[1].trim();
				if (cond == "==") {
					var value = found[2].trim()
					condition.ventDiametersLogic = 'selected'
					condition.ventDiameters = [parseInt(value)];
				}
			}
		},
		dimXText: function(design) {
			return (design.fc5layout.ViewAngle == "3D") ? "Width (X)" : "Horizontal (X)";
		},
		dimYText: function(design) {
			return (design.fc5layout.ViewAngle == "3D") ? "Height (Y)" : "Vertical (Y)";
		},
		dimZText: function(design) {
			return (design.fc5layout.ViewAngle == "3D") ? "Depth (Z)" : "Depth (Z)";
		},
		changeDim: function(dimension, design) {
			var cval = dimension;
			var nval = "";
			var newL = 0
			var chars = "";

			if (_.isNumber(cval)) {
				return cval
			}
			if (design.units == "IP") {
				if (cval === "") {
					newL = 0;
				} else {
					chars = "0123456789,.'-"
					for (var i = 0; i < cval.length; i++) {
						var th = cval.substring(i, i + 1);
						if (chars.indexOf(th) != -1) {
							nval += th
						}
					}
					var sp = [];
					if (nval.indexOf("'") != -1) {
						sp = nval.split("'")
					} else {
						sp = nval.split("-")
					}

					if (sp.length == 1) {
						if (sp[0] == "") {
							newL = 0;
						} else {
							newL = parseFloat(sp[0]);
						}
						
					} else {
						newL = parseInt(sp[0]) * 12;
						if (sp[1].length > 0) {
							newL += parseFloat(sp[1]);
						}
					}
				}
			} else {
				if (cval === "") {
					newL = 0;
				} else {
					newL = parseFloat(cval);
				}
			}

			// if (isNaN(newL)) {
			// 	alert("Could not convert your entry to a valid number. Please try again");
			// 	return false;
			// }

			return newL;
		},
		memberOf: function(user, checkedMembers) {
			var existing = _.find(checkedMembers, function(m) {
				return m._id == user._id || m == user._id
			})
			return (existing) ? true : false;
		},
		toggleCheck: function(user, checkedMembers) {
			var i = _.findIndex(checkedMembers, function(u) {
				return u._id == user._id || u == user._id;
			})
			if (i == -1) {
				checkedMembers.push(user);
			} else {
				checkedMembers.splice(i, 1)
			}
		},
		Get3DAngle: function(s1, s2) {
			var vc = {
				X: 0,
				Y: 0,
				Z: 0
			};
			var v1 = {
				X: 0,
				Y: 0,
				Z: 0
			};
			var v2 = {
				X: 0,
				Y: 0,
				Z: 0
			};

			if (s1.X1 == s2.X1 && s1.Y1 == s2.Y1 && s1.Z1 == s2.Z1) {
				vc.X = s1.X1
				vc.Y = s1.Y1
				vc.Z = s1.Z1
				v1.X = s1.X2 - vc.X
				v1.Y = s1.Y2 - vc.Y
				v1.Z = s1.Z2 - vc.Z
				v2.X = s2.X2 - vc.X
				v2.Y = s2.Y2 - vc.Y
				v2.Z = s2.Z2 - vc.Z
			} else if (s1.X1 == s2.X2 && s1.Y1 == s2.Y2 && s1.Z1 == s2.Z2) {
				vc.X = s1.X1
				vc.Y = s1.Y1
				vc.Z = s1.Z1
				v1.X = s1.X2 - vc.X
				v1.Y = s1.Y2 - vc.Y
				v1.Z = s1.Z2 - vc.Z
				v2.X = s2.X1 - vc.X
				v2.Y = s2.Y1 - vc.Y
				v2.Z = s2.Z1 - vc.Z
			} else if (s1.X2 == s2.X1 && s1.Y2 == s2.Y1 && s1.Z2 == s2.Z1) {
				vc.X = s1.X2
				vc.Y = s1.Y2
				vc.Z = s1.Z2
				v1.X = s1.X1 - vc.X
				v1.Y = s1.Y1 - vc.Y
				v1.Z = s1.Z1 - vc.Z
				v2.X = s2.X2 - vc.X
				v2.Y = s2.Y2 - vc.Y
				v2.Z = s2.Z2 - vc.Z
			} else if (s1.X2 == s2.X2 && s1.Y2 == s2.Y2 && s1.Z2 == s2.Z2) {
				vc.X = s1.X2
				vc.Y = s1.Y2
				vc.Z = s1.Z2
				v1.X = s1.X1 - vc.X
				v1.Y = s1.Y1 - vc.Y
				v1.Z = s1.Z1 - vc.Z
				v2.X = s2.X1 - vc.X
				v2.Y = s2.Y1 - vc.Y
				v2.Z = s2.Z1 - vc.Z
			} else {
				return -1;
			}

			var Mag1 = Math.sqrt(v1.X * v1.X + v1.Y * v1.Y + v1.Z * v1.Z);
			v1.X = v1.X / Mag1;
			v1.Y = v1.Y / Mag1;
			v1.Z = v1.Z / Mag1;

			var Mag2 = Math.sqrt(v2.X * v2.X + v2.Y * v2.Y + v2.Z * v2.Z);
			v2.X = v2.X / Mag2;
			v2.Y = v2.Y / Mag2;
			v2.Z = v2.Z / Mag2;

			return Math.abs(Math.acos(v1.X * v2.X + v1.Y * v2.Y + v1.Z * v2.Z) * (180 / Math.PI) - 180);
		},
		axisChangeHorizontal: function(v, design) {
			if (design.viewMode == '2D') {
				return Math.abs(v.X1 - v.X2) > 0
			} else {
				return Math.abs(v.X1 - v.X2) + Math.abs(v.Z1 - v.Z2) > 0
			}
		},
		axisChangeVertical: function(v, design) {
			return Math.abs(v.Y1 - v.Y2) > 0
		},
		GetTrueAngle: function(s1, s2) {
			if ((s1.DimX == 0 && s1.DimY == 0 && s1.DimZ == 0) || (s2.DimX == 0 && s2.DimY == 0 && s2.DimZ == 0)) {
				return this.Get3DAngle(s1, s2)
			};

			var v1 = {
				X: 0,
				Y: 0,
				Z: 0
			};
			var v2 = {
				X: 0,
				Y: 0,
				Z: 0
			};

			var Mag1 = Math.sqrt(s1.DimX * s1.DimX + s1.DimY * s1.DimY + s1.DimZ * s1.DimZ);
			v1.X = s1.DimX / Mag1;
			v1.Y = s1.DimY / Mag1;
			v1.Z = s1.DimZ / Mag1;

			var Mag2 = Math.sqrt(s2.DimX * s2.DimX + s2.DimY * s2.DimY + s2.DimZ * s2.DimZ);
			v2.X = s2.DimX / Mag2;
			v2.Y = s2.DimY / Mag2;
			v2.Z = s2.DimZ / Mag2;
			var ret = Math.abs(Math.acos(v1.X * v2.X + v1.Y * v2.Y + v1.Z * v2.Z) * (180 / Math.PI) - 180);
			if (ret == 180) {
				ret = 0
			};
			return ret;
		},
		downLength:function(design,val){
			if (!design){
				return val
			}
			if (design.units == "SI"){
				return val/100
			} else {
				return val/12
			}
		},
		getUnits: function(design, type) {
			if (!design){
				return ''
			}
			if (design.units == "SI") {
				if (type == "Pres") {
					return "Pa"
				}
				if (type == "Vol") {
					return "m3/h"
				}
				if (type == "Vel") {
					return "m/s"
				}
				if (type == "Temp") {
					return "C"
				}
				if (type == "Mass") {
					return "kg/s"
				}
				if (type == "Inp") {
					return "kW"
				}
				if (type == "Len") {
					return "m"
				}
				if (type == "Dia") {
					return "mm"
				}
			} else {
				if (type == "Pres") {
					return "inWC"
				}
				if (type == "Vol") {
					return "CFM"
				}
				if (type == "Vel") {
					return "fpm"
				}
				if (type == "Temp") {
					return "F"
				}
				if (type == "Mass") {
					return "lb/h"
				}
				if (type == "Inp") {
					return "MBTU"
				}
				if (type == "Len") {
					return "ft."
				}
				if (type == "Dia") {
					return "in"
				}
			}
		},
		showShippingRow:function(designs) {
			var found = _.find(designs, function(design) {
				return design.include && design.include.stack
			})
			var result = found ? true : false
			return result
		},
		getDehydrateAttribute:function(obj, attribute) {
			var value = obj[attribute]
			if (!value || _.isString(value)) {
				return value
			} else {
				return value._id
			}
		},
		dehydrateAttribute:function(obj, attribute) {
			var value = this.getDehydrateAttribute(obj, attribute)
			obj[attribute] = value
		},
		jobLabel:function(job) {
			if (!job){
				return ''
			}
			// return job.externalReference && job.externalReference != '' ? job.externalReference : job.jobCode
			return job.jobCode
		},
		objectId:function() {
			return ObjectId()
		},
		scrubPayload: function(o){
			return _.omit(o, function(value, key, object) {
				return key.indexOf("$") != -1 && key != "__v"
			});
		},
		getOutletDiameter: function(v, design, constrain){
			var result = null
			if (design.application.stackAs == "DRY" || this.isDry(v.Appl, design)){
				result =  v.Appl && v.Appl.OutDia
			} else {
				result =  v.Appl && v.Appl.OutDim1
			}
			if (result && result != ''){
				return parseInt(result)
			} else if (!constrain) {
				return v.Diameter
			}
		},
		computeGrandTotal: function (quote, quote_extras_config) {
			var extras = JSON.parse(quote_extras_config)
			quote.grandTotal = quote.totalPrice;
			quote.extras = []
			_.each(extras, function (extra){
				if (extra.active) {
					if (extra.strategy == "percent") {
						var additional = quote.totalPrice * extra.percent
						quote.grandTotal = quote.grandTotal + additional
						quote.extras.push({
							code: extra.code,
							price: additional,
							name: extra.description
						})
					}
				}
			})
		},
		convertScientificToDecimal: function(scientificNotation) {
			if (String(scientificNotation).indexOf('e') == -1) {
				return scientificNotation
			} else {
			 	var decimalValue = parseFloat(scientificNotation);
			  	var decimalString = decimalValue.toFixed(20); // Set the desired number of decimal places
			  	return decimalString.replace(/\.?0+$/, "");
			}
		},
		isScientific: function(val) {
			if (val != undefined) {
				return String(val).indexOf("e") > 0 ? true : false
			} else {
				return false
			}
		},
		isStackOnly: function(design) {
			return design.include.stack && !design.include.exhaust && !design.include.supply
		},
		isExhaustOnly:function(design) {
			return !design.include.stack && (design.include.exhaust || design.include.supply)
		}
	}
});
